<template>
    <div class="col-span-12 xl:col-span-6">
    <div class="intro-y box">
      <div
        class="
          flex flex-col
          sm:flex-row
          items-center
          p-5
          border-b border-gray-200
          dark:border-dark-5
        "
      >
        <h2 class="font-medium text-base mr-auto">
          {{ $t('views.settings.title') }}
        </h2>
      </div>
      <div class="p-5 relative">
        <LoadingBlock v-if="processing" />

        <div class="">
          <div class="relative">
            <label for="form-input-1" class="form-label"
              >{{ $t('views.settings.darkTheme') }}</label>
            <DarkModeSwitcher />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import LoadingBlock from "@/components/common/LoadingBlock.vue";
import DarkModeSwitcher from "@/components/common/dark-mode-switcher/Main.vue";


export default defineComponent({
    name: 'Settings',
    components: {
        LoadingBlock,
        DarkModeSwitcher
    },
    data: function() {
        return {
            processing: false
        }
    },
    mounted: function () {
        this.$store.commit("updateTopBarTitle", this.$i18n.t("menu.settings"));
    },
})
</script>
